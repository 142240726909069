import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import ExternalLink from '../../../components/ExternalLink';
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
export const _frontmatter = {
  "slug": "/services/website/accessibility",
  "title": "Button - Accessibility"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "accessibility",
      "style": {
        "position": "relative"
      }
    }}>{`Accessibility`}<a parentName="h2" {...{
        "href": "#accessibility",
        "aria-label": "accessibility permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "pay-attention-to",
      "style": {
        "position": "relative"
      }
    }}>{`Pay attention to`}<a parentName="h3" {...{
        "href": "#pay-attention-to",
        "aria-label": "pay attention to permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`It is advisable to use colour combinations provided by the implementation.`}</strong>{` These combinations are ensured to comply with WCAG AA requirements. When customising colours, refer to `}<InternalLink size="M" href="/foundation/design-tokens/colour" mdxType="InternalLink">{`colour guidelines`}</InternalLink>{` to ensure accessibility.`}</li>
      <li parentName="ul">{`In most cases, prefer using normal size buttons over small buttons. Default sized buttons are easier for users to notice and press.`}</li>
      <li parentName="ul">{`Pay attention to the button role. If you use the button as a link instead of an action (i.e. the button press causes a page load), you must specify a `}<inlineCode parentName="li">{`role="link"`}</inlineCode>{` attribute to the button.`}</li>
      <li parentName="ul">{`HDS Buttons (even the Small variant) comply with `}<ExternalLink href="https://www.w3.org/TR/WCAG21/#target-size" mdxType="ExternalLink">{`WCAG 2.5.5 Target Size (AAA) guideline`}</ExternalLink>{`. Customizing button sizes is not recommended.`}</li>
    </ul>
    <h3 {...{
      "id": "related-accessibility-requirements",
      "style": {
        "position": "relative"
      }
    }}>{`Related accessibility requirements`}<a parentName="h3" {...{
        "href": "#related-accessibility-requirements",
        "aria-label": "related accessibility requirements permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">
        <ExternalLink href="https://www.w3.org/TR/WCAG21/#focus-visible" mdxType="ExternalLink">WCAG 2.4.7 - Focus visible</ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://www.w3.org/TR/WCAG21/#on-input" mdxType="ExternalLink">WCAG 3.2.2 - On input</ExternalLink>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      